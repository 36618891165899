import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Autoconhecimento',
        description: 'O desenvolvimento pessoal começa com a compreensão de si mesmo. Isso inclui identificar seus valores, crenças, paixões, pontos fortes e áreas de melhoria.'
    },
    {
        icon: <FiLayers />,
        title: 'Metas e Planejamento',
        description: 'Definir metas claras é fundamental para o desenvolvimento pessoal. Metas bem definidas proporcionam direção e motivação.'
    },
    {
        icon: <FiUsers />,
        title: 'Habilidades Interpessoais',
        description: 'Desenvolver habilidades de comunicação, empatia e resolução de conflitos é muito  importante para que se possa construir relacionamentos saudáveis e produtivos.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Gestão do Tempo',
        description: 'Gerenciar o tempo de forma eficaz é crucial para alcançar metas e ser produtivo, realizando tarefas de maneira eficiente. Isso envolve estabelecer prioridades, criar um cronograma e eliminar distrações.'
    },
    {
        icon: <FiUsers />,
        title: 'Saúde e Bem-estar',
        description: 'Cuidar do corpo e da mente é uma parte fundamental do desenvolvimento pessoal. Isso inclui uma alimentação saudável, exercícios regulares, sono adequado e práticas de bem-estar mental.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Equilíbrio',
        description: 'Encontrar um equilíbrio saudável entre trabalho e vida pessoal é essencial para o bem-estar geral. Isso envolve definir limites, estabelecer prioridades e reservar tempo para atividades que tragam felicidade e satisfação pessoal.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#about">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
