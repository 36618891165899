const BlogContent = [
    {
        images: '01',
        title: ' Como Mudar meu mind-set em 7 Passos',
        category: 'Desenvolvimento'
    },
    {
        images: '02',
        title: 'Como gerenciar seu tempo e terminar todas tarefas',
        category: 'Gerenciamento'
    },

    {
        images: '03',
        title: '7 Livros que mudaram minha vida',
        category: 'Auto Ajuda'
    },
    {
        images: '04',
        title: ' O milagre da manha, mude sua vida com pequenos habitos',
        category: 'Desenvolvimento'
    },
    {
        images: '01',
        title: ' Como falar nao  ',
        category: 'Gerenciamento'
    },
    {
        images: '02',
        title: 'Aprenda a gerenciar riquesa',
        category: 'Auto Ajuda'
    },
]

export default BlogContent;